<template>
  <div class="word-form" v-wechat-title="'我要咨询'">
    <div class="word-form-test">
      <p><span>*</span> 我要咨询</p>
      <div class="word-form-type">
        <span
          v-for="el in consultType"
          :key="el"
          :class="{ active: form.type == el }"
          @click="form.type = el"
        >{{ el }}</span
        >
      </div>
      <p><span>*</span> 请你具体描述</p>
      <van-field
        class="word-form-desc"
        v-model="form.desc"
        rows="2"
        :autosize="{ maxHeight: 100, minHeight: 100 }"
        type="textarea"
        maxlength="300"
        placeholder="请输入详细的问题描述"
        show-word-limit
      />
    </div>
    <div class="word-form-inp">
      <van-field
        label-width="60"
        required
        v-model="form.name"
        label="联系人"
        placeholder="请填写联系人"
      />
      <van-field
        label-width="60"
        required
        v-model="form.phone"
        :maxlength="11"
        label="手机号"
        placeholder="请填写手机号"
      />
      <van-field
        v-if="!userInfo"
        label-width="60"
        required
        label="验证码"
        v-model="form.code"
        :maxlength="6"
        placeholder="请填写验证码"
        :rules="[{ required: true, message: '请填写验证码' }]"
      >
        <template #button
        ><span @click="sendMobileCode" style="color: #ea0b06">{{
            phoneCodeBtn ? `重新发送(${reciprocalNum})` : '发送验证码'
          }}</span></template
        >
      </van-field>
    </div>
    <van-button block class="btn-red" @click="onSubmit">提交</van-button>
    <share :val="shareInfo"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getConsultCreate } from '@/api/news'
import { sendMobile, checkCaptchaMobile } from '@/api/common'
import share from '../components/share.vue'

export default {
  components: { share },
  data () {
    return {
      form: {
        type: '鸿联联创服务目录',
        desc: '',
        name: '',
        phone: '',
        code: '',
      },
      consultType: [
        '鸿联联创服务目录',
        '鸿联云平台操作',
        '鸿蒙化解决方案',
        '国产替代路径',
        '鸿蒙化硬件产品',
        '鸿联创如何合作',
      ],
      phoneCodeBtn: false,
      reciprocalNum: 60,
      shareInfo: {
        title: '我要咨询',
        desc: '专属技术顾问为您提供一对一免费咨询服务',
        img: require('@/assets/img/logoinimg.png')
      }
    }
  },
  computed: mapGetters(['userInfo']),
  mounted () {
    this.form.name = this.userInfo.realName
    this.form.phone = this.userInfo.phone
    if (this.$route.query.code) {
      this.wechatUtil.saveWechatInfo(this.$route.query.code, localStorage.getItem('openId'))
    }
  },
  methods: {
    sendMobileCode () {
      const { phone } = this.form
      if (this.phoneCodeBtn) {
        return
      }
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        this.$toast('手机号格式不正确')
        return
      }
      sendMobile(phone).then((code) => {
        this.$toast.success('发送成功')
        this.phoneCodeBtn = true
        this.reciprocalItme()
      })
    },
    reciprocalItme () {
      let _this = this
      let num = this.reciprocalNum
      let itme = null
      itme = setInterval(function () {
        num--
        if (num <= 0) {
          _this.phoneCodeBtn = false
          _this.reciprocalNum = 60
          clearInterval(itme)
          return
        }
        _this.reciprocalNum = num
      }, 1000)
    },
    onSubmit () {
      let {
        form,
        userInfo
      } = this
      let data = {
        consultTitle: form.type,
        consultContent: form.desc,
        contactDetail: form.phone,
        contactPerson: form.name,
      }
      if (form.desc == '') {
        this.$toast('请输入详细的问题描述')
        return
      }
      if (form.name == '') {
        this.$toast('请填写联系人')
        return
      }
      if (!/^1[3456789]\d{9}$/.test(form.phone)) {
        this.$toast('手机号格式不正确')
        return
      }
      if (form.code == '' && !userInfo) {
        this.$toast('请填写验证码')
        return
      }
      if (!userInfo) {
        checkCaptchaMobile({
          mobile: form.phone,
          code: form.code
        }).then(
          (res) => {
            if (!res) {
              this.$toast('验证码有误')
              return
            }
            getConsultCreate(data).then((res) => {
              this.$toast.success('提交成功！')
              this.form = {
                type: '鸿联联创服务目录',
                desc: '',
                name: '',
                phone: '',
                code: '',
              }
              this.$router.go(-1)
            })
          }
        )
      } else {
        getConsultCreate(data).then((res) => {
          this.$toast.success('提交成功！')
          this.form = {
            type: '鸿联联创服务目录',
            desc: '',
            name: '',
            phone: '',
            code: '',
          }
          this.$router.go(-1)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.word-form {
  background: #f6f6f6;
  min-height: 100%;
  padding: 16px;

  .word-form-test {
    padding: 18px 12px;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 12px;

    > p {
      font-size: 16px;
      line-height: 22px;
      color: #333333;
      margin-bottom: 8px;

      > span {
        color: #ea0b06;
      }
    }

    .word-form-type {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 18px;

      > span {
        margin-top: 10px;
        width: 49%;
        height: 38px;
        border: 1px solid #eeeeee;
        border-radius: 19px;
        line-height: 38px;
        text-align: center;
        font-size: 12px;
        color: #333;

        &.active {
          background: #ea0b06;
          border-color: #ea0b06;
          color: #fff;
        }
      }
    }

    .word-form-desc {
      background: #f9f9fb;
      border-radius: 4px;
      font-size: 12px;
    }
  }

  .word-form-inp {
    margin-bottom: 20px;

    .van-cell {
      padding-top: 17px;
      padding-bottom: 17px;
    }
  }
}
</style>
<style>
.word-form-inp .van-field__control {
  text-align: right;
}
</style>
